export const cartActionTypes = {
  UPDATE_ITEM: 'UPDATE_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
  REMOVE_ITEM_NO_AUTH: 'REMOVE_ITEM_NO_AUTH',
  TOGGLE_EMPTY: 'TOGGLE_EMPTY',
  UPDATE_SAVED_ITEM: 'UPDATE_SAVED_ITEM',
  PUT_SAVED_ITEMS: 'PUT_SAVED_ITEMS',
  PUT_SAVED_ITEMS_NO_AUTH: 'PUT_SAVED_ITEMS_NO_AUTH',
  PUT_CART_ITEMS: 'PUT_CART_ITEMS',
  PUT_CART_ITEMS_NO_AUTH: 'PUT_CART_ITEMS_NO_AUTH',
  UPDATE_CART: 'UPDATE_CART',
};
