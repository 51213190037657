export const databaseActionTypes = {
  SET_SUMMER_DATA: 'SET_SUMMER_DATA',
  SET_WINTER_DATA: 'SET_WINTER_DATA',
  GET_DATA: 'GET_DATA',
  UPDATE_PRODUCT_SUMMER: 'UPDATE_PRODUCT_SUMMER',
  UPDATE_PRODUCT_WINTER: 'UPDATE_PRODUCT_WINTER',
  UPDATE_SAVED_SUMMER: 'UPDATE_SAVED_SUMMER',
  UPDATE_SAVED_WINTER: 'UPDATE_SAVED_WINTER',
  REMOVE_PRODUCT_SUMMER: 'REMOVE_PRODUCT_SUMMER',
  REMOVE_PRODUCT_WINTER: 'REMOVE_PRODUCT_WINTER',
};
